import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

import icLogo from "../../components/assets/img/pages/produtos/ic-logo.png";

const FirstSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const { data } = props || [];
  const [allData, setAllData] = useState({});

  useEffect(() => {
    if(data.Contents) {
      let label = data.Contents.find(item => item.Key === "sobre-slider-label");
      let title = data.Contents.find(item => item.Key === "sobre-slider-title");
      let paragraphs = data.Contents.filter(item => item.Key.includes("sobre-slider-paragraph"));
      let img = data.Contents.find(item => item.Key === "sobre-slider-image");

      let objList = [];
      for(let i = 0; i < 3; i++) {
        let obj = {
          title: title ? title.Value : "Undefined",
          paragraphs: paragraphs ? paragraphs : null,
          img: img ? img.Value : icLogo,
        }
        objList.push(obj);
      }
      
      setAllData(allData => ({
        ...allData,
        label: label ? label.Value : "Undefined",
        objList,
      }));      
    }
  }, [data]);

  return (
    <section id="missao">
      <div className="def__label__box container-60" >
        <div className="rectangle"></div>
        <p className="label">{allData.label}</p>                           
      </div>
      <Slider {...settings} className="container-60">              
        {allData.objList ? allData.objList.map((item, i) => (
          <div className="slider__item" key={i}>
            <div style={{marginLeft: 50}}>                  
              <h3>{item.title}</h3>
              {item.paragraphs ? item.paragraphs.map((item, index) => (
                <p key={index}>{item.Value}</p>
              )) : null}
            </div>
            <img src={item.img} alt="Imagem" style={{marginRight: 50}}/>
          </div>
        )) : null}
      </Slider>
    </section>
  )
}

export default FirstSlider;